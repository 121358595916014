<template>
  <div class="portfolio-page">
    <Page-header
      :cover="require('../assets/images/portfolio-cover.png')"
      :title="$t('solutions')"
    />
    <!-- :desc="$t('testTxt')" -->
    <div class="container">
      <div class="solutions-section page">
        <Solution-card
          v-for="(solution, solutionIndex) in solutions"
          :key="solutionIndex"
          :solution="solution"
          @openSolution="toggelModal"
        />
      </div>
    </div>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <Modal
        :service="modalData"
        v-if="activeModal"
        @closeModal="toggelModal"
      />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SolutionCard from "../components/Layout/SolutionCard.vue";
import PageHeader from "../components/misc/PageHeader.vue";
import Modal from "../components/Layout/Modal.vue";

export default {
  components: { PageHeader, SolutionCard, Modal },
  computed: {
    ...mapGetters(["solutions"]),
  },
  data() {
    return {
      modalData: null,
      activeModal: false,
    };
  },
  methods: {
    toggelModal(ser) {
      this.activeModal = !this.activeModal;
      if (ser) {
        this.modalData = ser;
      }
    },
  },
};
</script>
=